import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="magic-items"></a><h2>Magic Items</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Intelligent, Cursed, &amp; Artifacts</span>
    </h2>
    <a id="intelligent-items"></a><h3>INTELLIGENT ITEMS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Magic items sometimes have intelligence of
their own. Magically imbued with sentience, these items think and feel
the same way characters do and should be treated as NPCs. Intelligent
items have extra abilities and sometimes extraordinary powers and
special purposes. Only permanent magic items (as opposed to single-use
items or those with charges) can be intelligent. (This means that
potions, scrolls, and wands, among other items, are never intelligent.)
In general, less than 1% of magic items have intelligence.</p>
    <p>Intelligent items can actually be considered creatures because
they have Intelligence, Wisdom, and Charisma scores. Treat them as
constructs. Intelligent items often have the ability to illuminate
their surroundings at will (as magic weapons do); many cannot see
otherwise.</p>
    <p>Unlike most magic items, intelligent items can activate their
own powers without waiting for a command word from their owner.
Intelligent items act during their owner&#8217;s turn in the initiative order.</p>
    <a id="intelligent-item-alignment"></a><h5>INTELLIGENT ITEM ALIGNMENT</h5>
    <p className="initial">Any item with intelligence has an alignment.
Note that intelligent weapons already have alignments, either stated or
by implication. If you&#8217;re generating a random intelligent weapon, that
weapon&#8217;s alignment must fit with any alignment-oriented special
abilities it has.</p>
    <p>Any character whose alignment does not correspond to that of
the item (except as noted by the asterisks on the table) gains one
negative level if he or she so much as picks up the item. Although this
negative level never results in actual level loss, it remains as long
as the item is in hand and cannot be overcome in any way (including
restoration spells). This negative level is cumulative with any other
penalties the item might already place on inappropriate wielders. Items
with Ego scores (see below) of 20 to 29 bestow two negative levels.
Items with Ego scores of 30 or higher bestow three negative levels.</p>
    <a id="table-intelligent-item-alignment"></a><p><b>INTELLIGENT ITEM ALIGNMENT</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "300px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>d%</th>
          <th>Alignment of Item</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;05</td>
          <td>Chaotic good</td>
        </tr>
        <tr>
          <td>06&#8211;15</td>
          <td>Chaotic neutral*</td>
        </tr>
        <tr className="odd-row">
          <td>16&#8211;20</td>
          <td>Chaotic evil</td>
        </tr>
        <tr>
          <td>21&#8211;25</td>
          <td>Neutral evil*</td>
        </tr>
        <tr className="odd-row">
          <td>26&#8211;30</td>
          <td>Lawful evil</td>
        </tr>
        <tr>
          <td>31&#8211;55</td>
          <td>Lawful good</td>
        </tr>
        <tr className="odd-row">
          <td>56&#8211;60</td>
          <td>Lawful neutral*</td>
        </tr>
        <tr>
          <td>61&#8211;80</td>
          <td>Neutral good*</td>
        </tr>
        <tr className="odd-row">
          <td>81&#8211;100</td>
          <td>Neutral</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>The item can also be used by any
character whose alignment corresponds to the nonneutral portion of the
item&#8217;s alignment.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="languages-spoken-by-item"></a><h5>LANGUAGES SPOKEN BY ITEM</h5>
    <p className="initial">Like a character, an intelligent item speaks
Common plus one additional language per point of Intelligence bonus.
Choose appropriate languages, taking into account the item&#8217;s origin and
purposes.</p>
    <a id="table-item-intelligence-wisdom-charisma-and-capabilities"></a><p><b>Table: ITEM INTELLIGENCE, WISDOM, CHARISMA, AND CAPABILITIES</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "7%"
          }}>d%</th>
          <th style={{
            "width": "15%"
          }}>Mental Ability Scores</th>
          <th style={{
            "width": "15%"
          }}>Communication</th>
          <th style={{
            "width": "25%"
          }}>Capabilities</th>
          <th style={{
            "width": "25%"
          }}>Senses</th>
          <th style={{
            "width": "12%"
          }}>Base Price Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;34</td>
          <td>Two at 12, one at 10</td>
          <td>Empathy<sup>1</sup></td>
          <td>One lesser power</td>
          <td>30 ft. vision and hearing</td>
          <td>+1,000 gp</td>
        </tr>
        <tr>
          <td>35&#8211;59</td>
          <td>Two at 13, one at 10</td>
          <td>Empathy<sup>1</sup></td>
          <td>Two lesser powers</td>
          <td>60 ft. vision and hearing</td>
          <td>+2,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>60&#8211;79</td>
          <td>Two at 14, one at 10</td>
          <td>Speech<sup>2</sup></td>
          <td>Two lesser powers</td>
          <td>120 ft. vision and hearing</td>
          <td>+4,000 gp</td>
        </tr>
        <tr>
          <td>80&#8211;91</td>
          <td>Two at 15, one at 10</td>
          <td>Speech<sup>2</sup></td>
          <td>Three lesser powers</td>
          <td>60 ft. darkvision and hearing</td>
          <td>+5,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>92&#8211;97</td>
          <td>Two at 16, one at 10</td>
          <td>Speech<sup>2 3</sup></td>
          <td>Three lesser powers</td>
          <td>60 ft. darkvision and hearing</td>
          <td>+6,000 gp</td>
        </tr>
        <tr>
          <td>98</td>
          <td>Two at 17, one at 10</td>
          <td>Speech, telepathy<sup>3 4</sup></td>
          <td>Three lesser powers and one greater power<sup>6</sup></td>
          <td>120 ft. darkvision and hearing</td>
          <td>+9,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>99</td>
          <td>Two at 18, one at 10</td>
          <td>Speech, telepathy<sup>4 5</sup></td>
          <td>Three lesser powers and two greater powers<sup>6</sup></td>
          <td>120 ft. darkvision, blindsense, and hearing</td>
          <td>+12,000 gp</td>
        </tr>
        <tr>
          <td className="last-row">100</td>
          <td className="last-row">Two at 19, one at 10</td>
          <td className="last-row">Speech, telepathy<sup>4 5</sup></td>
          <td className="last-row">Four lesser powers and three greater
powers<sup>6</sup></td>
          <td className="last-row">120 ft. darkvision, blindsense, and
hearing</td>
          <td className="last-row">+15,000 gp</td>
        </tr>
        <tr>
          <td colSpan="6">1 <i>The possessor feels urges and
sometimes emotions from the item that encourage or discourage certain
courses of action.</i></td>
        </tr>
        <tr>
          <td colSpan="6">2 <i>Like a character, an intelligent item
speaks Common plus one language per point of Intelligence bonus. It can
communicate telepathically withthe wielder.</i></td>
        </tr>
        <tr>
          <td colSpan="6">3 <i>The item can also read any languages
it can speak.</i></td>
        </tr>
        <tr>
          <td colSpan="6">4 <i>The item can use either communication
mode at will, with language use as any speaking item. It can
communicate telepathically with the wielder.</i></td>
        </tr>
        <tr>
          <td colSpan="6">5 <i>The item can read all languages as
well as use read magic.</i></td>
        </tr>
        <tr>
          <td colSpan="6">6 <i>The intelligent item can have a
special purpose (and corresponding dedicated power) rather than a
greater power, if appropriate.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="intelligent-item-powers"></a><h5>INTELLIGENT ITEM POWERS</h5>
    <p className="initial">The table above determines how many lesser and
greater powers an intelligent item has. To find the item&#8217;s specific
powers, choose or roll on the appropriate tables below.</p>
    <a id="table-intelligent-item-lesser-powers"></a><p><b>INTELLIGENT ITEM LESSER POWERS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "12%"
          }}>d%</th>
          <th>Lesser Power</th>
          <th style={{
            "width": "20%"
          }}>Base Price
Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;05</td>
          <td>Item can bless its allies 3/day</td>
          <td>+1,000 gp</td>
        </tr>
        <tr>
          <td>06&#8211;10</td>
          <td>Item can use faerie fire 3/day </td>
          <td>+1,100 gp</td>
        </tr>
        <tr className="odd-row">
          <td>11&#8211;13</td>
          <td>Item can cast minor image 1/day</td>
          <td>+2,200 gp</td>
        </tr>
        <tr>
          <td>14&#8211;20</td>
          <td>Item has deathwatch continually active</td>
          <td>+2,700 gp</td>
        </tr>
        <tr className="odd-row">
          <td>21&#8211;25</td>
          <td>Item can use detect magic at will</td>
          <td>+3,600 gp</td>
        </tr>
        <tr>
          <td>26&#8211;31</td>
          <td>Item has 10 ranks in Intimidate</td>
          <td>+5,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>32&#8211;33</td>
          <td>Item has 10 ranks in Decipher Script</td>
          <td>+5,000 gp</td>
        </tr>
        <tr>
          <td>34&#8211;36</td>
          <td>Item has 10 ranks in Knowledge (choose category)</td>
          <td>+5,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>37&#8211;40</td>
          <td>Item has 10 ranks in Search</td>
          <td>+5,000 gp</td>
        </tr>
        <tr>
          <td>41&#8211;45</td>
          <td>Item has 10 ranks in Spot</td>
          <td>+5,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>46&#8211;50</td>
          <td>Item has 10 ranks in Listen</td>
          <td>+5,000 gp</td>
        </tr>
        <tr>
          <td>51&#8211;54</td>
          <td>Item has 10 ranks in Spellcraft</td>
          <td>+5,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>55&#8211;60</td>
          <td>Item has 10 ranks in Sense Motive</td>
          <td>+5,000 gp</td>
        </tr>
        <tr>
          <td>61&#8211;66</td>
          <td>Item has 10 ranks in Bluff</td>
          <td>+5,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>67&#8211;72</td>
          <td>Item has 10 ranks in Diplomacy</td>
          <td>+5,000 gp</td>
        </tr>
        <tr>
          <td>73&#8211;77</td>
          <td>Item can cast major image 1/day</td>
          <td>+5,400 gp</td>
        </tr>
        <tr className="odd-row">
          <td>78&#8211;80</td>
          <td>Item can cast darkness 3/day</td>
          <td>+6,500 gp</td>
        </tr>
        <tr>
          <td>81&#8211;83</td>
          <td>Item can use hold person on an enemy 3/day</td>
          <td>+6,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>84&#8211;86</td>
          <td>Item can activate zone of truth 3/day</td>
          <td>+6,500 gp</td>
        </tr>
        <tr>
          <td>87&#8211;89</td>
          <td>Item can use daze monster 3/day</td>
          <td>+6,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>90&#8211;95</td>
          <td>Item can use locate object 3/day</td>
          <td>+6,500 gp</td>
        </tr>
        <tr>
          <td className="last-row">96&#8211;100</td>
          <td className="last-row">Item can use cure moderate wounds
(2d8+3) on wielder 3/day</td>
          <td className="last-row">+6,500 gp</td>
        </tr>
      </tbody>
    </table>
    <p>All powers function at the direction of the item, although
intelligent items generally follow the wishes of their owner.
Activating a power or concentrating on an active one is a standard
action the item takes.</p>
    <a id="table-intelligent-item-greater-powers"></a><p><b>INTELLIGENT ITEM GREATER POWERS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "12%"
          }}>d%</th>
          <th>Greater Power</th>
          <th style={{
            "width": "20%"
          }}>Base Price
Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;06</td>
          <td>Item can detect opposing alignment at will</td>
          <td>+7,200 gp</td>
        </tr>
        <tr>
          <td>07&#8211;10</td>
          <td>Item can detect undead at will</td>
          <td>+7,200 gp</td>
        </tr>
        <tr className="odd-row">
          <td>11&#8211;13</td>
          <td>Item can cause fear in an enemy at will</td>
          <td>+7,200 gp</td>
        </tr>
        <tr>
          <td>14&#8211;18</td>
          <td>Item can use dimensional anchor on a foe 1/day</td>
          <td>+10,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>19&#8211;23</td>
          <td>Item can use dismissal on a foe 1/day</td>
          <td>+10,000 gp</td>
        </tr>
        <tr>
          <td>24&#8211;28</td>
          <td>Item can use lesser globe of invulnerability 1/day</td>
          <td>+10,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>29&#8211;33</td>
          <td>Item can use arcane eye 1/day</td>
          <td>+10,000 gp</td>
        </tr>
        <tr>
          <td>34&#8211;37</td>
          <td>Item has continuous detect scrying effect</td>
          <td>+10,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>38&#8211;41</td>
          <td>Item creates wall of fire in a ring with the wielder at
the center 1/day</td>
          <td>+10,000 gp</td>
        </tr>
        <tr>
          <td>42&#8211;45</td>
          <td>Item can use quench on fires 3/day</td>
          <td>+16,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>46&#8211;50</td>
          <td>Item has status effect, usable at will</td>
          <td>+11,000 gp</td>
        </tr>
        <tr>
          <td>51&#8211;54</td>
          <td>Item can use gust of wind 3/day</td>
          <td>+11,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>55&#8211;59</td>
          <td>Item can use clairvoyance 3/day</td>
          <td>+16,000 gp</td>
        </tr>
        <tr>
          <td>60&#8211;64</td>
          <td>Item can create magic circle against opposing alignment
at will</td>
          <td>+16,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>65&#8211;68</td>
          <td>Item can use haste on its owner 3/day</td>
          <td>+16,000 gp</td>
        </tr>
        <tr>
          <td>69&#8211;73</td>
          <td>Item can create daylight 3/day</td>
          <td>+16,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>74&#8211;76</td>
          <td>Item can create deeper darkness 3/day</td>
          <td>+16,000 gp</td>
        </tr>
        <tr>
          <td>77&#8211;80</td>
          <td>Item can use invisibility purge (30 ft. range) 3/day</td>
          <td>+16,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>81&#8211;85</td>
          <td>Item can use slow on its enemies 3/day</td>
          <td>+16,000 gp</td>
        </tr>
        <tr>
          <td>86&#8211;91</td>
          <td>Item can locate creature 3/day</td>
          <td>+30,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>91&#8211;97</td>
          <td>Item can use fear against foes 3/day</td>
          <td>+30,000 gp</td>
        </tr>
        <tr>
          <td className="last-row">98&#8211;100</td>
          <td className="last-row">Item can use detect thoughts at will</td>
          <td className="last-row">+44,000 gp</td>
        </tr>
      </tbody>
    </table>
    <p>If the same power is rolled twice, roll again.</p>
    <a id="special-purpose-items"></a><h5>SPECIAL PURPOSE ITEMS</h5>
    <a id="table-intelligent-item-purpose"></a><p><b>INTELLIGENT ITEM PURPOSE</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>d%</th>
          <th>Purpose</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;20</td>
          <td>Defeat/slay diametrically opposed alignment*</td>
        </tr>
        <tr>
          <td>21&#8211;30</td>
          <td>Defeat/slay arcane spellcasters (including spellcasting
monsters and those that use spell-like abilities)</td>
        </tr>
        <tr className="odd-row">
          <td>31&#8211;40</td>
          <td>Defeat/slay divine spellcasters (including divine
entities and servitors)</td>
        </tr>
        <tr>
          <td>41&#8211;50</td>
          <td>Defeat/slay nonspellcasters</td>
        </tr>
        <tr className="odd-row">
          <td>51&#8211;55</td>
          <td>Defeat/slay a particular creature type (see the bane
special ability for choices)</td>
        </tr>
        <tr>
          <td>56&#8211;60</td>
          <td>Defeat/slay a particular race or kind of creature</td>
        </tr>
        <tr className="odd-row">
          <td>61&#8211;70</td>
          <td>Defend a particular race or kind of creature</td>
        </tr>
        <tr>
          <td>71&#8211;80</td>
          <td>Defeat/slay the servants of a specific deity</td>
        </tr>
        <tr className="odd-row">
          <td>81&#8211;90</td>
          <td>Defend the servants and interests of a specific deity</td>
        </tr>
        <tr>
          <td>91&#8211;95</td>
          <td>Defeat/slay all (other than the item and the wielder)</td>
        </tr>
        <tr className="odd-row">
          <td>96&#8211;100</td>
          <td>Choose one</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>The purpose of the neutral (N)
version of this item is to preserve the balance by defeating/slaying
powerful beings of the extreme alignments (LG, LE, CG, CE).</i></td>
        </tr>
      </tbody>
    </table>
    <a id="purpose"></a><h6>Purpose</h6>
    <p className="initial">An item&#8217;s purpose must suit the type and
alignment of the item and should always be treated reasonably. A
purpose of &#8220;defeat/slay arcane spellcasters&#8221; doesn&#8217;t mean that the
sword forces the wielder to kill every wizard she sees. Nor does it
mean that the sword believes it is possible to kill every wizard,
sorcerer, and bard in the world. It does mean that the item hates
arcane spellcasters and wants to bring the local wizard&#8217;s cabal to
ruin, as well as end the rule of a sorceress-queen in a nearby land.
Likewise, a purpose of &#8220;defend elves&#8221; doesn&#8217;t mean that if the wielder
is an elf, he only wants to help himself. It means that the item wants
to be used in furthering the cause of elves, stamping out their enemies
and aiding their leaders. A purpose of &#8220;defeat/slay all&#8221; isn&#8217;t just a
matter of self-preservation. It means that the item won&#8217;t rest (or let
its wielder rest) until it places itself above all others.</p>
    <a id="dedicated-power"></a><h5>DEDICATED POWER</h5>
    <p className="initial">A dedicated power operates only when an
intelligent item is in pursuit of its special purpose. This
determination is always made by the item. It should always be easy and
straightforward to see how the ends justify the means. Unlike its other
powers, an intelligent item can refuse to use its dedicated power even
if the owner is dominant (see Items against Characters, below).</p>
    <a id="table-special-purpose-item-dedicated-powers"></a><p><b>SPECIAL PURPOSE ITEM DEDICATED POWERS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>d%</th>
          <th>Dedicated Power</th>
          <th style={{
            "width": "20%"
          }}>Base Price
Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;06</td>
          <td>Item can use ice storm</td>
          <td>+50,000 gp</td>
        </tr>
        <tr>
          <td>07&#8211;12</td>
          <td>Item can use confusion</td>
          <td>+50,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>13&#8211;17</td>
          <td>Item can use phantasmal killer</td>
          <td>+50,000 gp</td>
        </tr>
        <tr>
          <td>18&#8211;24</td>
          <td>Item can use crushing despair</td>
          <td>+50,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>25&#8211;31</td>
          <td>Item can use dimension door on itself and wielder</td>
          <td>+50,000 gp</td>
        </tr>
        <tr>
          <td>32&#8211;36</td>
          <td>Item can use contagion (heightened to 4th level) as
touch attack</td>
          <td>+56,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>37&#8211;43</td>
          <td>Item can use poison (heightened to 4th level) as touch
attack</td>
          <td>+56,000 gp</td>
        </tr>
        <tr>
          <td>44&#8211;50</td>
          <td>Item can use rusting grasp as touch attack</td>
          <td>+56,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>51&#8211;56</td>
          <td>Item can cast 10d6 lightning bolt</td>
          <td>+60,000 gp</td>
        </tr>
        <tr>
          <td>57&#8211;62</td>
          <td>Item can cast 10d6 fireball</td>
          <td>+60,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>63&#8211;68</td>
          <td>Wielder gets +2 luck bonus on attacks, saves, and checks</td>
          <td>+80,000 gp</td>
        </tr>
        <tr>
          <td>69&#8211;74</td>
          <td>Item can use mass inflict light wounds</td>
          <td>+81,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>75&#8211;81</td>
          <td>Item can use song of discord</td>
          <td>+81,000 gp</td>
        </tr>
        <tr>
          <td>82&#8211;87</td>
          <td>Item can use prying eyes</td>
          <td>+81,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>88&#8211;92</td>
          <td>Item can cast 15d6 greater shout 3/day</td>
          <td>+130,000 gp</td>
        </tr>
        <tr>
          <td>93&#8211;98</td>
          <td>Item can use waves of exhaustion</td>
          <td>+164,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>99&#8211;100</td>
          <td>Item can use true resurrection on wielder, once per
month</td>
          <td>+200,000 gp</td>
        </tr>
      </tbody>
    </table>
    <a id="item-ego"></a><h5>ITEM EGO</h5>
    <p className="initial">Ego is a measure of the total power and force
of personality that an item possesses. Only after all aspects of an
item have been generated can its Ego score be calculated. An item&#8217;s Ego
score helps determine whether the item or the character is dominant in
their relationship, as detailed below.</p>
    <a id="table-item-ego"></a><p><b>ITEM EGO</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "80%"
          }}>Attribute of Item</th>
          <th>Ego Points</th>
        </tr>
        <tr className="odd-row">
          <td>Each +1 of item&#8217;s enhancement bonus</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Each +1 of bonus for special abilities</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>Each lesser power</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Each greater power</td>
          <td>2</td>
        </tr>
        <tr className="odd-row">
          <td>Special purpose (and dedicated power)</td>
          <td>4</td>
        </tr>
        <tr>
          <td>Telepathic ability</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>Read languages ability</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Read magic ability</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>Each +1 of Intelligence bonus</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Each +1 of Wisdom bonus</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>Each +1 of Charisma bonus</td>
          <td>1</td>
        </tr>
      </tbody>
    </table>
    <a id="items-against-characters"></a><h5>ITEMS AGAINST CHARACTERS</h5>
    <p className="initial">When an item has an Ego of its own, it has a
will of its own. The item is, of course, absolutely true to its
alignment. If the character who possesses the item is not true to that
alignment&#8217;s goals or the item&#8217;s special purpose, personality
conflict&#8212;item against character&#8212;results. Similarly, any item with an
Ego score of 20 or higher always considers itself superior to any
character, and a personality conflict results if the possessor does not
always agree with the item.</p>
    <p>When a personality conflict occurs, the possessor must make a
Will saving throw (DC = item&#8217;s Ego). If the possessor succeeds, she is
dominant. If she fails, the item is dominant. Dominance lasts for one
day or until a critical situation occurs (such as a major battle, a
serious threat to either the item or the character, and so on). Should
an item gain dominance, it resists the character&#8217;s desires and demands
concessions such as any of the following.</p>
    <ul>
      <li>
        <p>Removal of associates or items whose alignment or
personality is distasteful to the item.</p>
      </li>
      <li>
        <p>The character divesting herself of all other magic items
or items of a certain type.</p>
      </li>
      <li>
        <p>Obedience from the character so the item can direct where
they go for its own purposes.</p>
      </li>
      <li>
        <p>Immediate seeking out and slaying of creatures hateful to
the item.</p>
      </li>
      <li>
        <p>Magical protections and devices to protect the item from
molestation when it is not in use.</p>
      </li>
      <li>
        <p>That the character carry the item with her on all
occasions.</p>
      </li>
      <li>
        <p>That the character relinquish the item in favor of a more
suitable possessor due to alignment differences or conduct.</p>
      </li>
    </ul>
    <p>In extreme circumstances, the item can resort to even harsher
measures, such as the following acts: </p>
    <ul>
      <li>
        <p>Force its possessor into combat.</p>
      </li>
      <li>
        <p>Refuse to strike opponents.</p>
      </li>
      <li>
        <p>Strike at its wielder or her associates.</p>
      </li>
      <li>
        <p>Force its possessor to surrender to an opponent.</p>
      </li>
      <li>
        <p>Cause itself to drop from the character&#8217;s grasp.</p>
      </li>
    </ul>
    <p>Naturally, such actions are unlikely when harmony reigns
between the character&#8217;s and item&#8217;s alignments or when their purposes
and personalities are well matched. Even so, an item might wish to have
a lesser character possess it in order to easily establish and maintain
dominance over him, or a higher-level possessor so as to better
accomplish its goals.</p>
    <p>All magic items with personalities desire to play an important
role in whatever activity is under way, particularly combat. Such items
are rivals of each other, even if they are of the same alignment. No
intelligent item wants to share its wielder with others. An intelligent
item is aware of the presence of any other intelligent item within 60
feet, and most intelligent items try their best to mislead or distract
their host so that she ignores or destroys the rival. Of course,
alignment might change this sort of behavior. </p>
    <p>Items with personalities are never totally controlled or
silenced by the characters who possess them, even though they may never
successfully control their possessors. They may be powerless to force
their demands but remain undaunted and continue to air their wishes and
demands.</p>
    <a id="cursed-items"></a><h3>CURSED ITEMS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Cursed items are magic items with some sort of
potentially negative impact. Sometimes they&#8217;re directly bad for the
user; sometimes they&#8217;re just inconvenient. Occasionally they mix bad
with good, forcing characters to make difficult choices. </p>
    <a id="table-cursed-item-common-curses"></a><p><b>CURSED ITEM COMMON CURSES</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "300px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>d%</th>
          <th>Curse</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;15</td>
          <td>Delusion</td>
        </tr>
        <tr>
          <td>16&#8211;35</td>
          <td>Opposite effect or target</td>
        </tr>
        <tr className="odd-row">
          <td>36&#8211;45</td>
          <td>Intermittent functioning</td>
        </tr>
        <tr>
          <td>46&#8211;60</td>
          <td>Requirement</td>
        </tr>
        <tr className="odd-row">
          <td>61&#8211;75</td>
          <td>Drawback</td>
        </tr>
        <tr>
          <td>76&#8211;90</td>
          <td>Completely different effect</td>
        </tr>
        <tr className="odd-row">
          <td>91&#8211;100</td>
          <td>Substitute specific cursed item</td>
        </tr>
      </tbody>
    </table>
    <a id="delusion"></a><p><b>Delusion</b>: The user believes the item is what it appears
to be, yet it actually has no magical power other than to deceive. The
user is mentally fooled into thinking the item is functioning and
cannot be convinced otherwise without the help of a remove curse spell.</p>
    <a id="opposite-effect-or-target"></a><p><b>Opposite Effect or Target</b>: These cursed items
malfunction, so that either they do the opposite of what the creator
intended, or they target the user instead of someone else. The
interesting point to keep in mind here is that these items aren&#8217;t
always bad to have.</p>
    <p>Opposite-effect items include weapons that impose penalties on
attack and damage rolls rather than bonuses. Just as a character
shouldn&#8217;t necessarily immediately know what the enhancement bonus of a
noncursed magic item is, she shouldn&#8217;t immediately know that a weapon
is cursed. Once she knows, however, the item can be discarded unless
some sort of compulsion is placed upon it that compels the wielder to
keep and use it. In such cases, a remove curse spell is generally
needed to get rid of the item.</p>
    <a id="intermittent-functioning"></a><p><b>Intermittent Functioning</b>: The three varieties of
intermittent functioning items all function perfectly as described&#8212;at
least some of the time. The three varieties are unreliable, dependent,
and uncontrolled items.</p>
    <a id="unreliable"></a><p><i>Unreliable</i>: Each time the item is activated, there is a
5% chance (01&#8211;05 on d%) that it does not function.</p>
    <a id="dependent"></a><p><i>Dependent</i>: The item only functions in certain
situations. To determine what the situation is, either select a
situation or roll on the following table.</p>
    <a id="table-dependent-item-sitauations"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>d%</th>
          <th>Situation</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;03</td>
          <td>Temperature below freezing</td>
        </tr>
        <tr>
          <td>04&#8211;05</td>
          <td>Temperature above freezing</td>
        </tr>
        <tr className="odd-row">
          <td>06&#8211;10</td>
          <td>During the day</td>
        </tr>
        <tr>
          <td>11&#8211;15</td>
          <td>During the night</td>
        </tr>
        <tr className="odd-row">
          <td>16&#8211;20</td>
          <td>In direct sunlight</td>
        </tr>
        <tr>
          <td>21&#8211;25</td>
          <td>Out of direct sunlight</td>
        </tr>
        <tr className="odd-row">
          <td>26&#8211;34</td>
          <td>Underwater</td>
        </tr>
        <tr>
          <td>35&#8211;37</td>
          <td>Out of water</td>
        </tr>
        <tr className="odd-row">
          <td>38&#8211;45</td>
          <td>Underground</td>
        </tr>
        <tr>
          <td>46&#8211;55</td>
          <td>Aboveground</td>
        </tr>
        <tr className="odd-row">
          <td>56&#8211;60</td>
          <td>Within 10 feet of a random creature type</td>
        </tr>
        <tr>
          <td>61&#8211;64</td>
          <td>Within 10 feet of a random race or kind of creature</td>
        </tr>
        <tr className="odd-row">
          <td>65&#8211;72</td>
          <td>Within 10 feet of an arcane spellcaster</td>
        </tr>
        <tr>
          <td>73&#8211;80</td>
          <td>Within 10 feet of a divine spellcaster</td>
        </tr>
        <tr className="odd-row">
          <td>81&#8211;85</td>
          <td>In the hands of a nonspellcaster</td>
        </tr>
        <tr>
          <td>86&#8211;90</td>
          <td>In the hands of a spellcaster</td>
        </tr>
        <tr className="odd-row">
          <td>91&#8211;95</td>
          <td>In the hands of a creature of a particular alignment</td>
        </tr>
        <tr>
          <td>96</td>
          <td>In the hands of a creature of particular gender</td>
        </tr>
        <tr className="odd-row">
          <td>97&#8211;99</td>
          <td>On nonholy days or during particular astrological events</td>
        </tr>
        <tr>
          <td className="last-row">100</td>
          <td className="last-row">More than 100 miles from a particular
site</td>
        </tr>
      </tbody>
    </table>
    <a id="uncontrolled"></a><p><i>Uncontrolled</i>: An uncontrolled item occasionally
activates at random times. Roll d% every day. On a result of 01&#8211;05 the
item activates at some random point during that day. </p>
    <a id="requirement"></a><p><b>Requirement</b>: In a sense, a command word is a
requirement. Nevertheless, some items have much more stringent
requirements that must be met for them to be usable. To keep an item
with this kind of curse functioning, one or more of the following
conditions must be met.</p>
    <ul>
      <li>
        <p>Character must eat twice as much as normal.</p>
      </li>
      <li>
        <p>Character must sleep twice as much as normal.</p>
      </li>
      <li>
        <p>Character must undergo a specific quest (one time only,
and then item functions normally thereafter).</p>
      </li>
      <li>
        <p>Character must sacrifice (destroy) 100 gp worth of
valuables per day.</p>
      </li>
      <li>
        <p>Character must sacrifice (destroy) 2,000 gp worth of magic
items each week.</p>
      </li>
      <li>
        <p>Character must swear fealty to a particular noble or his
family.</p>
      </li>
      <li>
        <p>Character must discard all other magic items.</p>
      </li>
      <li>
        <p>Character must worship a particular deity.</p>
      </li>
      <li>
        <p>Character must change her name to a specific name. (The
item only works for characters of that name.)</p>
      </li>
      <li>
        <p>Character must add a specific class at the next
opportunity if not of that class already.</p>
      </li>
      <li>
        <p>Character must have a minimum number of ranks in a
particular skill.</p>
      </li>
      <li>
        <p>Character must sacrifice some part of her life energy (2
points of Constitution) one time. If the character gets the
Constitution points back (such as from a restoration spell), the item
ceases functioning. (The item does not cease functioning if the
character receives a Constitution increase caused by level gain, a
wish, or the use of a magic item.)</p>
      </li>
      <li>
        <p>Item must be cleansed with holy water each day.</p>
      </li>
      <li>
        <p>Item must be used to kill a living creature each day.</p>
      </li>
      <li>
        <p>Item must be bathed in volcanic lava once per month.</p>
      </li>
      <li>
        <p>Item must be used at least once a day, or it won&#8217;t
function again for its current possessor.</p>
      </li>
      <li>
        <p>Item must draw blood when wielded (weapons only). It can&#8217;t
be put away or exchanged for another weapon until it has scored a hit.</p>
      </li>
      <li>
        <p>Item must have a particular spell cast upon it each day
(such as bless, atonement, or animate objects).</p>
      </li>
    </ul>
    <p>Requirements are so dependent upon suitability to the item
that they should never be determined randomly. An item with a
requirement that is also intelligent often imposes its requirement
through its personality. If the requirement is not met, the item ceases
to function. If it is met, usually the item functions for one day
before the requirement must be met again (although some requirements
are one time only, others monthly, and still others continuous).</p>
    <a id="drawback"></a><p><b>Drawback</b>: Items with drawbacks are usually still
beneficial to the possessor but they also carry some negative aspect.
Although sometimes drawbacks occur only when the item is used (or held,
in the case of some items such as weapons), usually the drawback
remains with the character for as long as she has the item.</p>
    <p>Roll on the table below to generate a drawback that (unless
otherwise indicated) remains in effect as long as the item is in the
character&#8217;s possession.</p>
    <a id="table-drawbacks"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%</th>
          <th>Drawback</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;04</td>
          <td>Character&#8217;s hair grows 1 inch longer. Only happens once.</td>
        </tr>
        <tr>
          <td>05&#8211;09</td>
          <td>Character either shrinks 1/2 inch (01&#8211;50 on d%) or
grows that much taller (51&#8211;100). Only happens once.</td>
        </tr>
        <tr className="odd-row">
          <td>10&#8211;13</td>
          <td>Temperature around item is 10&deg;F cooler than normal.</td>
        </tr>
        <tr>
          <td>14&#8211;17</td>
          <td>Temperature around item is 10&deg;F warmer than normal.</td>
        </tr>
        <tr className="odd-row">
          <td>18&#8211;21</td>
          <td>Character&#8217;s hair color changes.</td>
        </tr>
        <tr>
          <td>22&#8211;25</td>
          <td>Character&#8217;s skin color changes.</td>
        </tr>
        <tr className="odd-row">
          <td>26&#8211;29</td>
          <td>Character now bears some identifying mark (tattoo,
weird glow, or the like).</td>
        </tr>
        <tr>
          <td>30&#8211;32</td>
          <td>Character&#8217;s gender changes.</td>
        </tr>
        <tr className="odd-row">
          <td>33&#8211;34</td>
          <td>Character&#8217;s race or kind changes.</td>
        </tr>
        <tr>
          <td>35</td>
          <td>Character is afflicted with a random disease that
cannot be cured.</td>
        </tr>
        <tr className="odd-row">
          <td>36&#8211;39</td>
          <td>Item continually emits a disturbing sound (moaning,
weeping, screaming, cursing, insults).</td>
        </tr>
        <tr>
          <td>40</td>
          <td>Item looks ridiculous (garishly colored, silly shape,
glows bright pink, . . .).</td>
        </tr>
        <tr className="odd-row">
          <td>41&#8211;45</td>
          <td>Character becomes selfishly possessive about the item.</td>
        </tr>
        <tr>
          <td>46&#8211;49</td>
          <td>Character becomes paranoid about losing the item and
afraid of damage occurring to it.</td>
        </tr>
        <tr className="odd-row">
          <td>50&#8211;51</td>
          <td>Character&#8217;s alignment changes.</td>
        </tr>
        <tr>
          <td>52&#8211;54</td>
          <td>Character must attack nearest creature (5% chance
[01&#8211;05 on d%] each day).</td>
        </tr>
        <tr className="odd-row">
          <td>55&#8211;57</td>
          <td>Character is stunned for 1d4 rounds once item function
is finished (or randomly, 1/day).</td>
        </tr>
        <tr>
          <td>58&#8211;60</td>
          <td>Character&#8217;s vision is blurry (&#8211;2 penalty on attack
rolls, saves, and skill checks requiring vision).</td>
        </tr>
        <tr className="odd-row">
          <td>61&#8211;64</td>
          <td>Character gains one negative level.</td>
        </tr>
        <tr>
          <td>65</td>
          <td>Character gains two negative levels.</td>
        </tr>
        <tr className="odd-row">
          <td>66&#8211;70</td>
          <td>Character must make a Will save each day or take 1
point of Intelligence damage.</td>
        </tr>
        <tr>
          <td>71&#8211;75</td>
          <td>Character must make a Will save each day or take 1
point of Wisdom damage.</td>
        </tr>
        <tr className="odd-row">
          <td>76&#8211;80</td>
          <td>Character must make a Will save each day or take 1
point of Charisma damage.</td>
        </tr>
        <tr>
          <td>81&#8211;85</td>
          <td>Character must make a Fortitude save each day or take 1
point of Constitution damage.</td>
        </tr>
        <tr className="odd-row">
          <td>86&#8211;90</td>
          <td>Character must make a Fortitude save each day or take 1
point of Strength damage.</td>
        </tr>
        <tr>
          <td>91&#8211;95</td>
          <td>Character must make a Fortitude save each day or take 1
point of Dexterity damage.</td>
        </tr>
        <tr className="odd-row">
          <td>96</td>
          <td>Character is polymorphed into a specific creature (5%
chance [01&#8211;05 on d%] each day).</td>
        </tr>
        <tr>
          <td>97</td>
          <td>Character cannot cast arcane spells.</td>
        </tr>
        <tr className="odd-row">
          <td>98</td>
          <td>Character cannot cast divine spells.</td>
        </tr>
        <tr>
          <td>99</td>
          <td>Character cannot cast any spells.</td>
        </tr>
        <tr className="odd-row">
          <td>100</td>
          <td>Either pick one of the above that&#8217;s appropriate or
create a drawback specifically for that item.</td>
        </tr>
      </tbody>
    </table>
    <a id="specific-cursed-items"></a><h5>SPECIFIC CURSED ITEMS</h5>
    <p className="initial">Specific Cursed Items are provided as examples
of cursed items. They are given creation prerequisites, should someone
want to intentionally create them (although that does not need to be
the origin of the item). Note, however, two exceptions: The crystal
hypnosis ball and the bag of devouring cannot be created by any known
means.</p>
    <p>A simple detect magic spell yields a misleading aura and
strength, often indicating that the item is a noncursed item of similar
sort. An identify spell only has a 1% chance per caster level to reveal
a cursed item&#8217;s true properties, including the cursed aspect. Analyze
dweomer reveals the true nature of a cursed item.</p>
    <a id="table-specific-cursed-items"></a><p><b>Table: SPECIFIC CURSED ITEMS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr>
          <th>d%</th>
          <th>Item</th>
          <th>Market Price</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;05</td>
          <td><a href="#incense-of-obsession">Insence of obsession</a></td>
          <td>200 gp</td>
        </tr>
        <tr>
          <td>06&#8211;15</td>
          <td><a href="#ring-of-clumsiness">Ring of clumsiness</a></td>
          <td>500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>16&#8211;20</td>
          <td><a href="#amulet-of-inescapable-location">Amulet of inescapable location</a> </td>
          <td>1,000 gp</td>
        </tr>
        <tr>
          <td>21&#8211;25</td>
          <td><a href="#stone-of-weight-loadstone">Stone of weight</a> </td>
          <td>1,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>26&#8211;30</td>
          <td><a href="#bracers-of-defenselessness">Bracers of defenselessness</a> </td>
          <td>1,200 gp</td>
        </tr>
        <tr>
          <td>31&#8211;35</td>
          <td><a href="#gauntlets-of-fumbling">Gauntlets of fumbling</a></td>
          <td>1,300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>36&#8211;40</td>
          <td><a href="#sword-cursed">&#8211;2 sword, cursed</a> </td>
          <td>1,500 gp</td>
        </tr>
        <tr>
          <td>41&#8211;43</td>
          <td><a href="#armor-of-rage">Armor of rage</a> </td>
          <td>1,600 gp</td>
        </tr>
        <tr className="odd-row">
          <td>44&#8211;46</td>
          <td><a href="#medallion-of-thought-projection">Medallion of thought projection</a> </td>
          <td>1,800 gp</td>
        </tr>
        <tr>
          <td>47&#8211;52</td>
          <td><a href="#flask-of-curses">Flask of curses</a> </td>
          <td>2,100 gp</td>
        </tr>
        <tr className="odd-row">
          <td>53&#8211;54</td>
          <td><a href="#dust-of-sneezing-and-chocking">Dust of sneezing and choking</a> </td>
          <td>2,400 gp</td>
        </tr>
        <tr>
          <td>55</td>
          <td><a href="#helm-of-opposite-alignment">Helm of opposite alignment</a> </td>
          <td>4,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>56&#8211;60</td>
          <td><a href="#potion-of-poison">Potion of poison</a> </td>
          <td>5,000 gp</td>
        </tr>
        <tr>
          <td>61</td>
          <td><a href="#broom-of-animated-attack">Broom of animated attack</a> </td>
          <td>5,200 gp</td>
        </tr>
        <tr className="odd-row">
          <td>62&#8211;63</td>
          <td><a href="#robe-of-powerlessness">Robe of powerlessness</a> </td>
          <td>5,500 gp</td>
        </tr>
        <tr>
          <td>64</td>
          <td><a href="#vacuous-grimoire">Vacuous grimoire</a> </td>
          <td>6,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>65&#8211;68</td>
          <td><a href="#spear-cursed-backbiter">Spear, cursed backbiter</a> </td>
          <td>7,500 gp</td>
        </tr>
        <tr>
          <td>69&#8211;70</td>
          <td><a href="#armor-of-arrow-attraction">Armor of arrow attraction</a> </td>
          <td>9,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>71&#8211;72</td>
          <td><a href="#net-of-snaring">Net of snaring</a> </td>
          <td>10,000 gp</td>
        </tr>
        <tr>
          <td>73&#8211;75</td>
          <td><a href="#bag-of-devouring">Bag of devouring</a></td>
          <td> &#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>76&#8211;80</td>
          <td><a href="#mace-of-blood">Mace of blood</a> </td>
          <td>16,000 gp</td>
        </tr>
        <tr>
          <td>81&#8211;85</td>
          <td><a href="#robe-of-vermin">Robe of vermin</a> </td>
          <td>16,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>86&#8211;88</td>
          <td><a href="#periapt-of-foul-rotting">Periapt of foul rotting</a> </td>
          <td>17,000 gp</td>
        </tr>
        <tr>
          <td>89&#8211;92</td>
          <td><a href="#sword-berserking">Sword, berserking</a> </td>
          <td>17,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>93&#8211;96</td>
          <td><a href="#boots-of-dancing">Boots of dancing</a> </td>
          <td>30,000 gp</td>
        </tr>
        <tr>
          <td>97</td>
          <td><a href="#crystal-hypnosis-ball">Crystal hypnosis ball</a> </td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>98</td>
          <td><a href="#necklace-of-strangulation">Necklace of strangulation</a> </td>
          <td>60,000 gp</td>
        </tr>
        <tr>
          <td>99</td>
          <td><a href="#cloak-of-poisonousness">Cloak of poisonousness</a> </td>
          <td>62,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>100</td>
          <td><a href="#scarab-of-death">Scarab of death</a></td>
          <td> 80,000 gp</td>
        </tr>
      </tbody>
    </table>
    <a id="amulet-of-inescapable-location"></a><p><b>Amulet of Inescapable Location</b>: This device is
typically worn on a chain or as a brooch. It appears, to magical
analysis, to prevent location, scrying or detection or influence by
detect thoughts or telepathy. It seems to be an amulet of proof against
detection and location. Actually, the amulet gives the wearer a &#8211;10
penalty on all saves against divination spells.</p>
    <p>Moderate abjuration; CL 10th; Create Wondrous Item, bestow
curse; Price 1,000 gp.</p>
    <a id="armor-of-arrow-attraction"></a><p><b>Armor of Arrow Attraction</b>: Magical analysis indicates
that this armor is a normal suit of +3 full plate. However, the armor
is cursed. It works normally with regard to melee attacks but actually
serves to attract ranged weapons. The wearer takes a &#8211;15 penalty to AC
against any attack by a ranged weapon. The true nature of the armor
does not reveal itself until the character is fired upon in earnest.</p>
    <p>Strong abjuration; CL 16th; Craft Magic Arms and Armor, bestow
curse; Price 9,000 gp.</p>
    <a id="armor-of-rage"></a><p><b>Armor of Rage</b>: This armor is similar in appearance to
armor of command and functions as a suit of +1 full plate. However,
when it is worn, the armor causes the character to take a &#8211;4 penalty to
Charisma. All unfriendly characters within 300 feet have a +1 morale
bonus on attack rolls against her. The effect is not noticeable to the
wearer or those affected. (In other words, the wearer does not
immediately notice that donning the armor is the cause of her problems,
nor do foes understand the reason for the depth of their enmity.)</p>
    <p>Strong necromancy; CL 16th; Craft Magic Arms and Armor, bestow
curse; Price 1,600 gp.</p>
    <a id="bag-of-devouring"></a><p><b>Bag of Devouring</b>: This bag appears to be an ordinary
sack. Detection for magical properties makes it seem as if it were a
bag of holding. The sack is, however, a lure used by an
extradimensional creature&#8212;in fact, one of its feeding orifices.</p>
    <p>Any substance of animal or vegetable nature is subject to
&#8220;swallowing&#8217;&#8217; if thrust within the bag. The bag of devouring is 90%
likely to ignore any initial intrusion, but any time thereafter that it
senses living flesh within (such as if someone reaches into the bag to
pull something out), it is 60% likely to close around the offending
member and attempt to draw the whole victim in. The bag has a +8 bonus
on grapple checks made to pull someone in.</p>
    <p>The bag can hold up to 30 cubic feet of matter. It acts as a
bag of holding type I, but each hour it has a 5% cumulative chance of
swallowing the contents and then spitting the stuff out in some
nonspace or on some other plane. Creatures drawn within are consumed in
1 round. The bag destroys the victim&#8217;s body and prevents any form of
raising or resurrection that requires part of the corpse. There is a
50% chance that a wish, miracle, or true resurrection spell can restore
a devoured victim to life. Check once for each destroyed creature. If
the check fails, the creature cannot be brought back to life by mortal
magic.</p>
    <p>Moderate conjuration; CL 17th; In effect, this is a creature
and cannot be created; Price n/a.</p>
    <a id="boots-of-dancing"></a><p><b>Boots of Dancing</b>: These boots initially appear and
function as one of the other kinds of magic boots. But when the wearer
is in (or fleeing from) melee combat, boots of dancing impede movement,
making him behave as if irresistible dance had been cast upon him. Only
a remove curse spell enables the wearer to be rid of the boots once
their true nature is revealed.</p>
    <p>Strong enchantment; CL 16th; Create Wondrous Item,
irresistible dance; Price 30,000 gp.</p>
    <a id="bracers-of-defenselessness"></a><p><b>Bracers of Defenselessness</b>: These appear to be bracers
of armor +5 and actually serve as such until the wearer is attacked in
anger by an enemy with a Challenge Rating equal to or greater than her
level. At that moment and thereafter, the bracers cause a &#8211;5 penalty to
AC. Once their curse is activated, bracers of defenselessness can be
removed only by means of a remove curse spell.</p>
    <p>Moderate conjuration; CL 16th; Create Wondrous Item, mage
armor, bestow curse; Price 1,200 gp.</p>
    <a id="broom-of-animated-attack"></a><p><b>Broom of Animated Attack</b>: This item is
indistinguishable in appearance from a normal broom. It is identical to
a broom of flying by all tests short of attempted use.</p>
    <p>If a command is spoken, the broom does a loop-the-loop with
its hopeful rider, dumping him on his head from 1d4+5 feet off the
ground (no falling damage, since the fall is less than 10 feet). The
broom then attacks the victim, swatting the face with the straw or twig
end and beating him with the handle end.</p>
    <p>The broom gets two attacks per round with each end (two swats
with the straw and two with the handle, for a total of four attacks per
round). It attacks with a +5 bonus on each attack roll. The straw end
causes a victim to be blinded for 1 round when it hits. The handle
deals 1d6 points of damage when it hits. The broom has AC 13, 18 hit
points, and hardness 4.</p>
    <p>Moderate transmutation; CL 10th; Create Wondrous Item, fly,
animate objects; Price 5,200 gp.</p>
    <a id="cloak-of-poisonousness"></a><p><b>Cloak of Poisonousness</b>: This cloak is usually made of a
woolen material, although it can be made of leather. A detect poison
spell can reveal the presence of poison impregnated in the cloak&#8217;s
fabric. The garment can be handled without harm, but as soon as it is
actually donned the wearer is killed instantly unless she succeeds on a
DC 28 Fortitude save.</p>
    <p>Once donned, a cloak of poisonousness can be removed only with
a remove curse spell; doing this destroys the magical property of the
cloak. If a neutralize poison spell is then used, it is possible to
revive the victim with a raise dead or resurrection spell, but not
before.</p>
    <p>Strong abjuration; CL 15th; Create Wondrous Item, poison, and
limited wish or miracle; Price 62,000 gp.</p>
    <a id="crystal-hypnosis-ball"></a><p><b>Crystal Hypnosis Ball</b>: This cursed item is
indistinguishable from a normal crystal ball. However, anyone
attempting to use the scrying device becomes fascinated for 1d6
minutes, and a telepathic suggestion is implanted in his mind (Will DC
19 negates).</p>
    <p>The user of the device believes that the desired creature or
scene was viewed, but actually he came under the influence of a
powerful wizard, lich, or even some power or being from another plane.
Each further use brings the crystal hypnosis ball gazer deeper under
the influence of the controller, either as a servant or a tool. Note
that throughout this time, the user remains unaware of his subjugation.</p>
    <p>Moderate divination; CL 17th; In effect, this is a minor
artifact and cannot be created; Price n/a.</p>
    <a id="dust-of-sneezing-and-chocking"></a><p><b>Dust of Sneezing and Choking</b>: This fine dust appears to
be dust of appearance. If cast into the air, it causes those within a
20- foot spread to fall into fits of sneezing and coughing. Those
failing a DC 15 Fortitude save take 2d6 points of Constitution damage
immediately. In addition, those failing a second DC 15 Fortitude save 1
minute later are dealt 1d6 points of Constitution damage. Those who
succeed on either saving throw are nonetheless disabled by choking
(treat as stunned) for 5d4 rounds.</p>
    <p>Faint conjuration; CL 7th; Create Wondrous Item, poison; Price
2,400 gp.</p>
    <a id="flask-of-curses"></a><p><b>Flask of Curses</b>: This item looks like an ordinary
beaker, bottle, container, decanter, flask, or jug. It may contain a
liquid, or it may emit smoke. When the flask is first unstoppered, all
within 30 feet must make a DC 17 Will save or be cursed, taking a &#8211;2
penalty on attack rolls, saving throws, and skill checks until a remove
curse spell is cast upon them.</p>
    <p>Moderate conjuration; CL 7th; Create Wondrous Item, bestow
curse; Price 2,100 gp.</p>
    <a id="gauntlets-of-fumbling"></a><p><b>Gauntlets of Fumbling</b>: These gauntlets may be of supple
leather or heavy protective material suitable for use with armor. In
the former instance, they appear to be gloves of Dexterity. In the
latter case, they appear to be gauntlets of ogre power. The gauntlets
perform according to every test as if they were gloves of Dexterity or
gauntlets of ogre power until the wearer finds herself under attack or
in a life-and-death situation. At that time, the curse is activated.
The wearer becomes fumble-fingered, with a 50% chance each round of
dropping anything held in either hand. The gauntlets also lower
Dexterity by 2 points. Once the curse is activated, the gloves can be
removed only by means of a remove curse spell, a wish, or a miracle.</p>
    <p>Moderate transmutation; CL 7th; Create Wondrous Item, bestow
curse; Price 1,300 gp.</p>
    <a id="helm-of-opposite-alignment"></a><p><b>Helm of Opposite Alignment</b>: This metal hat looks like a
typical helmet. When placed upon the head, however, its curse
immediately takes effect (Will DC 15 negates). On a failed save, the
alignment of the wearer is radically altered to an alignment as
different as possible from the former alignment&#8212;good to evil, chaotic
to lawful, neutral to some extreme commitment (LE, LG, CE, or CG).
Alteration in alignment is mental as well as moral, and the individual
changed by the magic thoroughly enjoys his new outlook. A character who
succeeds on his save can continue to wear the helmet without suffering
the effect of the curse, but if he takes it off and later puts it on
again, another save is required. The curse only works once; that is, a
character whose alignment has been changed cannot change it again by
donning the helmet a second time.</p>
    <p>Only a wish or a miracle can restore former alignment, and the
affected individual does not make any attempt to return to the former
alignment. (In fact, he views the prospect with horror and avoids it in
any way possible.) If a character of a class with an alignment
requirement is affected, an atonement spell is needed as well if the
curse is to be obliterated. When a helm of opposite alignment has
functioned once, it loses its magical properties.</p>
    <p>Strong transmutation; CL 12th; Create Wondrous Item, creator
must be 12th level; Price 4,000 gp;Weight 3 lb.</p>
    <a id="incense-of-obsession"></a><p><b>Incense of Obsession</b>: These blocks of incense appear to
be incense of meditation. If meditation and prayer are conducted while
incense of obsession is burning nearby, its odor and smoke cause the
user to become totally confident that her spell ability is superior,
due to the magic incense. The user is determined to use her spells at
every opportunity, even when not needed or when useless. The user
remains obsessed with her abilities and spells until all have been used
or cast, or until 24 hours have elapsed.</p>
    <p>Moderate enchantment; CL 6th; Create Wondrous Item, bestow
curse; Price 200 gp.</p>
    <a id="mace-of-blood"></a><p><b>Mace of Blood</b>: This +3 heavy mace must be coated in
blood every day, or its bonus fades away (until the mace is coated
again). The character using this mace must make a DC 13 Will save every
day it is within his possession or become chaotic evil.</p>
    <p>Moderate abjuration; CL 8th; Craft Magic Arms and Armor,
creator must be at least 9th level and chaotic evil; Price 16,000 gp.</p>
    <a id="medallion-of-thought-projection"></a><p><b>Medallion of Thought Projection</b>: This device seems like
a medallion of thoughts, even down to the range at which it functions,
except that the thoughts overheard are muffled and distorted, requiring
a DC 15 Will save to sort out. However, while the user thinks she is
picking up the thoughts of others, all she is really hearing are
figments created by the medallion itself. These illusory thoughts
always seem plausible and thus can seriously mislead any who rely upon
them. What&#8217;s worse, unknown to her, the cursed medallion actually
broadcasts her thoughts to creatures in the path of the beam, thus
alerting them to her presence.</p>
    <p>Faint divination; CL 7th; Create Wondrous Item, detect
thoughts, ghost sound; Price 1,800 gp.</p>
    <a id="necklace-of-strangulation"></a><p><b>Necklace of Strangulation</b>: A necklace of strangulation
appears to be a rare and wondrous piece of valuable jewelry and, short
of the use of something as powerful as a miracle or a wish, can only be
identified as a cursed item when placed around a character&#8217;s neck. The
necklace immediately constricts, dealing 6 points of damage per round.
It cannot be removed by any means short of a limited wish, wish, or
miracle and remains clasped around the victim&#8217;s throat even after his
death. Only when he has decayed to a dry skeleton (after approximately
one month) does the necklace loosen, ready for another victim.</p>
    <p>Strong conjuration; CL 18th; Create Wondrous Item, slay
living; Price 60,000 gp.</p>
    <a id="net-of-snaring"></a><p><b>Net of Snaring</b>: This net provides a +3 bonus on attack
rolls but can only be used underwater, thus making it a somewhat useful
item rather than what most would really call a cursed item. Underwater,
it can be commanded to shoot forth up to 30 feet to trap a creature.</p>
    <p>Moderate evocation; CL 8th; Craft Magic Arms and Armor,
freedom of movement; Price 10,000 gp.</p>
    <a id="periapt-of-foul-rotting"></a><p><b>Periapt of Foul Rotting</b>: This engraved gem appears to
be of little value. If any character keeps the periapt in her
possession for more than 24 hours, she contracts a terrible rotting
affliction that permanently drains 1 point of Dexterity, Constitution,
and Charisma every week. The periapt (and the affliction) can be
removed only by application of a remove curse spell followed by a cure
disease and then a heal, miracle, limited wish, or wish spell. The
rotting can also be countered by crushing a periapt of health and
sprinkling its dust upon the afflicted character (a full-round action),
whereupon the periapt of foul rotting likewise crumbles to dust.</p>
    <p>Faint abjuration; CL 10th; Create Wondrous Item, contagion;
Price 17,000 gp.</p>
    <a id="potion-of-poison"></a><p><b>Potion of Poison</b>: This potion has lost its once
beneficial magical abilities and has become a potent poison. The
imbiber must make a DC 16 Fortitude save or take 1d10 points of
Constitution damage. A minute later he must save again (DC 16) or take
1d10 points of Constitution damage.</p>
    <p>Moderate conjuration; CL 12th; Craft Wondrous Item, poison;
Price 5,000 gp.</p>
    <a id="robe-of-powerlessness"></a><p><b>Robe of Powerlessness</b>: A robe of powerlessness appears
to be a magic robe of another sort. As soon as a character dons this
garment, she takes a &#8211;10 penalty to Strength and Intelligence,
forgetting spells and magic knowledge accordingly. The robe can be
removed easily, but in order to restore mind and body, the character
must receive a remove curse spell followed by heal.</p>
    <p>Moderate transmutation; CL 13th; Create Wondrous Item, bestow
curse, permanency; Price 5,500 gp.</p>
    <a id="robe-of-vermin"></a><p><b>Robe of Vermin</b>: The wearer notices nothing unusual when
the robe is donned, other than that it offers great magical defense (as
a cloak of protection +4). However, as soon as he is in a situation
requiring concentration and action against hostile opponents, the true
nature of the garment is revealed: The wearer immediately suffers a
multitude of bites from the insects that magically infest the garment.
He must cease all other activities in order to scratch, shift the robe,
and generally show signs of the extreme discomfort caused by the bites
and movement of these pests.</p>
    <p>The wearer takes a &#8211;5 penalty on initiative checks and a &#8211;2
penalty on all attack rolls, saves, and skill checks. If he tries to
cast a spell, he must make a Concentration check (DC 20 + spell level)
or lose the spell.</p>
    <p>Moderate abjuration; CL 13th; Create Wondrous Item, summon
swarm, creator must be at least 13th level; Price 16,500 gp.</p>
    <a id="ring-of-clumsiness"></a><p><b>Ring of Clumsiness</b>: This ring operates exactly like a
ring of feather falling. However, it also makes the wearer clumsy. She
takes a &#8211;4 penalty to Dexterity and has a 20% chance of spell failure
when trying to cast any arcane spell that has a somatic component.
(This chance of spell failure stacks with other arcane spell failure
chances.)</p>
    <p>Strong transmutation; CL 15th; Forge Ring, feather fall,
bestow curse; Price 500 gp.</p>
    <a id="scarab-of-death"></a><p><b>Scarab of Death</b>: This small pin appears to be any one
of the various beneficial amulets, brooches, or scarabs. However, if it
is held for more than 1 round or carried by a living creature for 1
minute, it changes into a horrible burrowing beetlelike creature. The
thing tears through any leather or cloth, burrows into flesh, and
reaches the victim&#8217;s heart in 1 round, causing death. A DC 25 Reflex
save allows the wearer to tear the scarab away before it burrows out of
sight, but he still takes 3d6 points of damage. The beetle then returns
to its scarab form. Placing the scarab in a container of wood, ceramic,
bone, ivory, or metal prevents the monster from coming to life and
allows for long-term storage of the item.</p>
    <p>Strong abjuration; CL 19th; Create Wondrous Item, slay living;
Price 80,000 gp.</p>
    <a id="spear-cursed-backbiter"></a><p><b>Spear, Cursed Backbiter</b>: This is a +2 shortspear, but
each time it is used in melee against a foe and the attack roll is a
natural 1, it damages its wielder instead of her intended target. When
the curse takes effect, the spear curls around to strike its wielder in
the back, automatically dealing the damage to the wielder. The curse
even functions when the spear is hurled, and in such a case the damage
to the hurler is doubled.</p>
    <p>Moderate evocation; CL 10th; Craft Magic Arms and Armor,
bestow curse; Price 7,500 gp.</p>
    <a id="stone-of-weight-loadstone"></a><p><b>Stone of Weight (Loadstone)</b>: This stone appears to be a
dark, smoothly polished stone. It reduces the possessor&#8217;s base land
speed to one-half of normal. Once picked up, the stone cannot be
disposed of by any nonmagical means&#8212;if it is thrown away or smashed, it
reappears somewhere on his person. If a remove curse spell is cast upon
a loadstone, the item may be discarded normally and no longer haunts
the individual.</p>
    <p>Faint transmutation; CL 5th; Create Wondrous Item, slow; Price
1,000 gp.</p>
    <a id="sword-cursed"></a><p><b>&#8211;2 Sword, Cursed</b>: This longsword performs well against
targets in practice, but when used against an opponent in combat, it
causes its wielder to take a &#8211;2 penalty on attack rolls.</p>
    <p>All damage dealt is also reduced by 2 points, but never below
a minimum of 1 point of damage on any successful hit. After one week in
a character&#8217;s possession, the sword always forces that character to
employ it rather than another weapon. The sword&#8217;s owner automatically
draws it and fights with it even when she meant to draw or ready some
other weapon. The sword can be gotten rid of only by means of limited
wish, wish, or miracle.</p>
    <p>Strong evocation; CL 15th; Craft Magic Arms and Armor, bestow
curse, and limited wish or miracle; Price 1,500 gp.</p>
    <a id="sword-berserking"></a><p><b>Sword, Berserking</b>: This item appears to have the
characteristics of a +2 greatsword. However, whenever the sword is used
in battle, its wielder goes berserk (gaining all the benefits and
drawbacks of the barbarian&#8217;s rage ability). He attacks the nearest
creature and continues to fight until unconscious or dead or until no
living thing remains within 30 feet. Although many see this sword as a
cursed object, others see it as a boon.</p>
    <p>Moderate evocation; CL 8th; Craft Magic Arms and Armor, rage,
bestow curse; Price 17,500 gp.</p>
    <a id="vacuous-grimoire"></a><p><b>Vacuous Grimoire</b>: A book of this sort looks like a
normal one on some mildly interesting topic. Any character who opens
the work and reads so much as a single word therein must make two DC 15
Will saves. The first is to determine if the reader takes 1 point of
permanent Intelligence drain. The second is to find out if the reader
takes 2 points of permanent Wisdom drain. To destroy the book, a
character must burn it while casting remove curse. If the grimoire is
placed with other books, its appearance instantly alters to conform to
the look of those other works.</p>
    <p>Strong enchantment; CL 20th; Create Wondrous Item, feeblemind;
Price 6,000 gp.</p>
    <a id="artifacts"></a><h3>ARTIFACTS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Artifacts are extremely powerful. Rather than
merely another form of magic equipment, they are the sorts of legendary
relics that whole campaigns can be based on. Each could be the center
of a whole set of adventures&#8212;a quest to recover it, a fight against a
opponent wielding it, a mission to cause its destruction, and so on.</p>
    <p>No table has been included to randomly generate specific
artifacts, since these items should only enter a campaign through
deliberate choice on your part.</p>
    <a id="minor-artifacts"></a><h5>MINOR ARTIFACTS</h5>
    <p className="initial">Minor artifacts are not necessarily unique
items. Even so, they are magic items that no longer can be created, at
least by common mortal means.</p>
    <a id="minor-artifact-descriptions"></a><h6>Minor Artifact Descriptions</h6>
    <p className="initial">Described below is a selection of the most
well-known (not necessarily the most numerous) minor artifacts.</p>
    <a id="book-of-infinite-spells"></a><p><b>Book of Infinite Spells</b>: This work bestows upon any
character of any class the ability to use the spells within its pages.
However, any character not already able to use spells gains one
negative level for as long as the book is in her possession or while
she uses its power. A book of infinite spells contains 1d8+22 pages.
The nature of each page is determined by a dice roll: 01&#8211;50, arcane
spell; 51&#8211;100, divine spell.</p>
    <p>Determine the exact spell by using the tables for determining
major scroll spells.</p>
    <p>Once a page is turned, it can never be flipped back&#8212;paging
through a book of infinite spells is a one-way trip. If the book is
closed, it always opens again to the page it was on before the book was
closed. When the last page is turned, the book vanishes.</p>
    <p>Once per day the owner of the book can cast the spell to which
the book is opened. If that spell happens to be one that is on the
character&#8217;s class spell list, she can cast it up to four times per day.
The pages cannot be ripped out without destroying the book. Similarly,
the spells cannot be cast as scroll spells, nor can they be copied into
a spellbook&#8212;their magic is bound up permanently within the book itself.</p>
    <p>The owner of the book need not have the book on her person in
order to use its power. The book can be stored in a place of safety
while the owner is adventuring and still allow its owner to cast spells
by means of its power.</p>
    <p>Each time a spell is cast, there is a chance that the energy
connected with its use causes the page to magically turn despite all
precautions. The owner knows this and may even benefit from the turning
by gaining access to a new spell. The chance of a page turning depends
on the spell the page contains and what sort of spellcaster the owner
is.</p>
    <a id="table-book-of-infinite-spells"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "550px"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>Condition</th>
          <th>Chance of
Page Turning</th>
        </tr>
        <tr className="odd-row">
          <td>Caster employing a spell usable by own class and level</td>
          <td>10%</td>
        </tr>
        <tr>
          <td>Caster employing a spell not usable by own class and
level</td>
          <td>20%</td>
        </tr>
        <tr className="odd-row">
          <td>Nonspellcaster employing divine spell</td>
          <td>25%</td>
        </tr>
        <tr>
          <td className="last-row">Nonspellcaster employing arcane spell</td>
          <td className="last-row">30%</td>
        </tr>
      </tbody>
    </table>
    <p>Treat each spell use as if a scroll were being employed, for
purposes of determining casting time, spell failure, and so on.</p>
    <p>Strong (all schools); CL 18th;Weight 3 lb.</p>
    <a id="deck-of-many-things"></a><p><b>Deck of Many Things</b>: A deck of many things (both
beneficial and baneful) is usually found in a box or leather pouch.
Each deck contains a number of cards or plaques made of ivory or
vellum. Each is engraved with glyphs, characters, and sigils. As soon
as one of these cards is drawn from the pack, its magic is bestowed
upon the person who drew it, for better or worse.</p>
    <p>The character with a deck of many things who wishes to draw a
card must announce how many cards she will draw before she begins.
Cards must be drawn within 1 hour of each other, and a character can
never again draw from this deck any more cards than she has announced.
If the character does not willingly draw her allotted number (or if she
is somehow prevented from doing so), the cards flip out of the deck on
their own. Exception: If the jester is drawn, the possessor of the deck
may elect to draw two additional cards.</p>
    <p>Each time a card is taken from the deck, it is replaced
(making it possible to draw the same card twice) unless the draw is the
jester or the fool, in which case the card is discarded from the pack.
A deck of many things contains 22 cards. To simulate the magic cards,
you may want to use tarot cards, as indicated in the second column of
the accompanying table. If no tarot deck is available, substitute
ordinary playing cards instead, as indicated in the third column. The
effects of each card, summarized on the table, are fully described
below.</p>
    <a id="table-deck-of-many-things"></a><p><b>DECK OF MANY THINGS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>Plaque</th>
          <th style={{
            "width": "15%"
          }}>Tarot Card</th>
          <th style={{
            "width": "15%"
          }}>Playing Card</th>
          <th style={{
            "width": "60%"
          }}>Summary of Effect</th>
        </tr>
        <tr className="odd-row">
          <td>Balance</td>
          <td>XI. Justice</td>
          <td>Two of spades</td>
          <td>Change alignment instantly.</td>
        </tr>
        <tr>
          <td>Comet</td>
          <td>Two of swords</td>
          <td>Two of diamonds</td>
          <td>Defeat the next monster you meet to gain one level.</td>
        </tr>
        <tr className="odd-row">
          <td>Donjon</td>
          <td>Four of swords</td>
          <td>Ace of spades</td>
          <td>You are imprisoned.</td>
        </tr>
        <tr>
          <td>Euryale</td>
          <td>Ten of swords</td>
          <td>Queen of spades</td>
          <td>&#8211;1 penalty on all saving throws henceforth.</td>
        </tr>
        <tr className="odd-row">
          <td>The Fates</td>
          <td>Three of cups</td>
          <td>Ace of hearts</td>
          <td>Avoid any situation you choose . . . once.</td>
        </tr>
        <tr>
          <td>Flames</td>
          <td>XV. The Devil</td>
          <td>Queen of clubs</td>
          <td>Enmity between you and an outsider.</td>
        </tr>
        <tr className="odd-row">
          <td>Fool</td>
          <td>0. The Fool</td>
          <td>Joker (with trademark)</td>
          <td>Lose 10,000 experience points and you must draw again.</td>
        </tr>
        <tr>
          <td>Gem</td>
          <td>Seven of cups</td>
          <td>Two of hearts</td>
          <td>Gain your choice of twenty-five pieces of jewelry or
fifty gems.</td>
        </tr>
        <tr className="odd-row">
          <td>Idiot</td>
          <td>Two of pentacles</td>
          <td>Two of clubs</td>
          <td>Lose Intelligence (permanent drain). You may draw again.</td>
        </tr>
        <tr>
          <td>Jester</td>
          <td>XII. The Hanged Man</td>
          <td>Joker (without trademark)</td>
          <td>Gain 10,000 XP or two more draws from the deck.</td>
        </tr>
        <tr className="odd-row">
          <td>Key</td>
          <td>V. The Hierophant</td>
          <td>Queen of hearts</td>
          <td>Gain a major magic weapon.</td>
        </tr>
        <tr>
          <td>Knight</td>
          <td>Page of swords</td>
          <td>Jack of hearts</td>
          <td>Gain the service of a 4th-level fighter.</td>
        </tr>
        <tr className="odd-row">
          <td>Moon</td>
          <td>XVIII. The Moon</td>
          <td>Queen of diamonds</td>
          <td>You are granted 1d4 wishes.</td>
        </tr>
        <tr>
          <td>Rogue</td>
          <td>Five of swords</td>
          <td>Jack of spades</td>
          <td>One of your friends turns against you.</td>
        </tr>
        <tr className="odd-row">
          <td>Ruin</td>
          <td>XVI. The Tower</td>
          <td>King of spades</td>
          <td>Immediately lose all wealth and real property.</td>
        </tr>
        <tr>
          <td>Skull</td>
          <td>XIII. Death</td>
          <td>Jack of clubs</td>
          <td>Defeat dread wraith or be forever destroyed.</td>
        </tr>
        <tr className="odd-row">
          <td>Star</td>
          <td>XVII. The Star</td>
          <td>Jack of diamonds</td>
          <td>Immediately gain a +2 inherent bonus to one ability
score.</td>
        </tr>
        <tr>
          <td>Sun</td>
          <td>XIX. The Sun</td>
          <td>King of diamonds</td>
          <td>Gain beneficial medium wondrous item and 50,000 XP.</td>
        </tr>
        <tr className="odd-row">
          <td>Talons</td>
          <td>Queen of pentacles</td>
          <td>Ace of clubs</td>
          <td>All magic items you possess disappear permanently.</td>
        </tr>
        <tr>
          <td>Throne</td>
          <td>Four of staves</td>
          <td>King of hearts</td>
          <td>Gain a +6 bonus on Diplomacy checks plus a small keep.</td>
        </tr>
        <tr className="odd-row">
          <td>Vizier</td>
          <td>IX. The Hermit</td>
          <td>Ace of diamonds</td>
          <td>Know the answer to your next dilemma.</td>
        </tr>
        <tr>
          <td className="last-row">The Void</td>
          <td className="last-row">Eight of swords</td>
          <td className="last-row">King of clubs</td>
          <td className="last-row">Body functions, but soul is trapped
elsewhere.</td>
        </tr>
      </tbody>
    </table>
    <a id="deck-of-many-things-balance"></a><p><i>Balance</i>: The character must change to a radically
different alignment. If the character fails to act according to the new
alignment, she gains a negative level.</p>
    <a id="deck-of-many-things-comet"></a><p><i>Comet</i>: The character must single-handedly defeat the
next hostile monster or monsters encountered, or the benefit is lost.
If successful, the character gains enough XP to attain the next
experience level.</p>
    <a id="deck-of-many-things-donjon"></a><p><i>Donjon</i>: This card signifies imprisonment&#8212; either by the
imprisonment spell or by some powerful being. All gear and spells are
stripped from the victim in any case. Draw no more cards.</p>
    <a id="deck-of-many-things-eryale"></a><p><i>Euryale</i>: The medusalike visage of this card brings a
curse that only the fates card or a deity can remove. The &#8211;1 penalty on
all saving throws is otherwise permanent.</p>
    <a id="deck-of-many-things-fates"></a><p><i>Fates</i>: This card enables the character to avoid even an
instantaneous occurrence if so desired, for the fabric of reality is
unraveled and respun. Note that it does not enable something to happen.
It can only stop something from happening or reverse a past occurrence.
The reversal is only for the character who drew the card; other party
members may have to endure the situation.</p>
    <a id="deck-of-many-things-flames"></a><p><i>Flames</i>: Hot anger, jealousy, and envy are but a few of
the possible motivational forces for the enmity. The enmity of the
outsider can&#8217;t be ended until one of the parties has been slain.
Determine the outsider randomly, and assume that it attacks the
character (or plagues her life in some way) within 1d20 days.</p>
    <a id="deck-of-many-things-fool"></a><p><i>Fool</i>: The payment of XP and the redraw are mandatory.
This card is always discarded when drawn, unlike all others except the
jester.</p>
    <a id="deck-of-many-things-gem"></a><p><i>Gem</i>: This card indicates wealth. The jewelry is all
gold set with gems, each piece worth 2,000 gp, the gems 1,000 gp value
each. </p>
    <a id="deck-of-many-things-idiot"></a><p><i>Idiot</i>: This card causes the drain of 1d4+1 points of
Intelligence immediately. The additional draw is optional.</p>
    <a id="deck-of-many-things-jester"></a><p><i>Jester</i>: This card is always discarded when drawn,
unlike all others except the fool. The redraws are optional.</p>
    <a id="deck-of-many-things-key"></a><p><i>Key</i>: The magic weapon granted must be one usable by the
character. It suddenly appears out of nowhere in the character&#8217;s hand.</p>
    <a id="deck-of-many-things-knight"></a><p><i>Knight</i>: The fighter appears out of nowhere and serves
loyally until death. He or she is of the same race (or kind) and gender
as the character.</p>
    <a id="deck-of-many-things-moon"></a><p><i>Moon</i>: This card sometimes bears the image of a
moonstone gem with the appropriate number of wishes shown as gleams
therein; sometimes it depicts a moon with its phase indicating the
number of wishes (full = four; gibbous = three; half = two; quarter =
one). These wishes are the same as those granted by the 9th-level
wizard spell and must be used within a number of minutes equal to the
number received.</p>
    <a id="deck-of-many-things-rogue"></a><p><i>Rogue</i>: When this card is drawn, one of the character&#8217;s
NPC friends (preferably a cohort) is totally alienated and forever
after hostile. If the character has no cohorts, the enmity of some
powerful personage (or community, or religious order) can be
substituted. The hatred is secret until the time is ripe for it to be
revealed with devastating effect.</p>
    <a id="deck-of-many-things-ruin"></a><p><i>Ruin</i>: As implied by its name, when this card is drawn,
all nonmagical possessions of the drawer are lost.</p>
    <a id="deck-of-many-things-skull"></a><p><i>Skull</i>: A dread wraith appears. Treat this creature as
an unturnable undead. The character must fight it alone&#8212;if others help,
they get dread wraiths to fight as well. If the character is slain, she
is slain forever and cannot be revived, even with a wish or a miracle.</p>
    <a id="deck-of-many-things-star"></a><p><i>Star</i>: The 2 points are added to any ability the
character chooses. They cannot be divided among two abilities. </p>
    <a id="deck-of-many-things-sun"></a><p><i>Sun</i>: Roll for a medium wondrous item until a useful
item is indicated.</p>
    <a id="deck-of-many-things-talons"></a><p><i>Talons</i>: When this card is drawn, every magic item owned
or possessed by the character is instantly and irrevocably gone.</p>
    <a id="deck-of-many-things-throne"></a><p><i>Throne</i>: The character becomes a true leader in people&#8217;s
eyes. The castle gained appears in any open area she wishes (but the
decision where to place it must be made within 1 hour).</p>
    <a id="deck-of-many-things-vizier"></a><p><i>Vizier</i>: This card empowers the character drawing it
with the one-time ability to call upon a source of wisdom to solve any
single problem or answer fully any question upon her request. The query
or request must be made within one year. Whether the information gained
can be successfully acted upon is another question entirely.</p>
    <a id="deck-of-many-things-the-void"></a><p><i>The Void</i>: This black card spells instant disaster. The
character&#8217;s body continues to function, as though comatose, but her
psyche is trapped in a prison somewhere&#8212;in an object on a far plane or
planet, possibly in the possession of an outsider. A wish or a miracle
does not bring the character back, instead merely revealing the plane
of entrapment. Draw no more cards.</p>
    <p>Strong (all schools); CL 20th.</p>
    <a id="hammer-of-thunderbolts"></a><p><b>Hammer of Thunderbolts</b>: This +3 Large returning
warhammer deals 4d6 points of damage on any hit. Further, if the
wielder wears a belt of giant Strength and gauntlets of ogre power and
he knows that the hammer is a hammer of thunderbolts (not just a +3
warhammer), the weapon can be used to full effect: It gains a total +5
enhancement bonus, allows all belt and gauntlet bonuses to stack (only
when using this weapon), and strikes dead any giant upon whom it scores
a hit (Fortitude DC 20 negates the death effect but not the damage).</p>
    <p>When hurled, on a successful attack the hammer emits a great
noise, like a clap of thunder, causing all creatures within 90 feet to
be stunned for 1 round (Fortitude DC 15 negates). The hammer&#8217;s range
increment is 30 feet.</p>
    <p>Strong evocation, necromancy, and transmutation; CL 20th;
Weight 15 lb.</p>
    <a id="philosophers-stone"></a><p><b>Philosopher&#8217;s Stone</b>: This rare substance appears to be
an ordinary, sooty piece of blackish rock. If the stone is broken open
(break DC 20), a cavity is revealed at the stone&#8217;s heart. This cavity
is lined with a magical type of quicksilver that enables any arcane
spellcaster to transmute base metals (iron and lead) into silver and
gold. A single philosopher&#8217;s stone can turn from up to 5,000 pounds of
iron into silver, or up to 1,000 pounds of lead into gold. However, the
quicksilver becomes unstable once the stone is opened and loses its
potency within 24 hours, so all transmutations must take place within
that period.</p>
    <p>The quicksilver found in the center of the stone may also be
put to another use. If mixed with any cure potion while the substance
is still potent, it creates a special oil of life that acts as a true
resurrection spell for any dead body it is sprinkled upon.</p>
    <p>Strong transmutation; CL 20th;Weight 3 lb.</p>
    <a id="sphere-of-annihilation"></a><p><b>Sphere of Annihilation</b>: A sphere of annihilation is a
globe of absolute blackness, a ball of nothingness 2 feet in diameter.
The object is actually a hole in the continuity of the multiverse. Any
matter that comes in contact with a sphere is instantly sucked into the
void, gone, and utterly destroyed. Only the direct intervention of a
deity can restore an annihilated character.</p>
    <p>A sphere of annihilation is static, resting in some spot as if
it were a normal hole. It can be caused to move, however, by mental
effort (think of this as a mundane form of telekinesis, too weak to
move actual objects but a force to which the sphere, being weightless,
is sensitive). A character&#8217;s ability to gain control of a sphere of
annihilation (or to keep controlling one) is based on the result of a
control check against DC 30 (a move action). A control check is 1d20 +
character level + character Int modifier. If the check succeeds, the
character can move the sphere (perhaps to bring it into contact with an
enemy) as a free action.</p>
    <p>Control of a sphere can be established from as far away as 40
feet (the character need not approach too closely). Once control is
established, it must be maintained by continuing to make control checks
(all DC 30) each round. For as long as a character maintains control
(does not fail a check) in subsequent rounds, he can control the sphere
from a distance of 40 feet + 10 feet per character level. The sphere&#8217;s
speed in a round is 10 feet +5 feet for every 5 points by which the
character&#8217;s control check result in that round exceeded 30.</p>
    <p>If a control check fails, the sphere slides 10 feet in the
direction of the character attempting to move it.</p>
    <p>If two or more creatures vie for control of a sphere of
annihilation, the rolls are opposed. If none are successful, the sphere
slips toward the one who rolled lowest.</p>
    <p>Should a gate spell be cast upon a sphere of annihilation,
there is a 50% chance (01&#8211;50 on d%) that the spell destroys it, a 35%
chance (51&#8211;85) that the spell does nothing, and a 15% chance (86&#8211;100)
that a gap is torn in the spatial fabric, catapulting everything within
a 180-foot radius into another plane. If a rod of cancellation touches
a sphere of annihilation, they negate each other in a tremendous
explosion. Everything within a 60-foot radius takes 2d6x10 points of
damage. Dispel magic and mage&#8217;s disjunction have no effect on a sphere.</p>
    <p>See also talisman of the sphere (below).</p>
    <p>Strong transmutation; CL 20th.</p>
    <a id="staff-of-the-magi"></a><p><b>Staff of the Magi</b>: A long wooden staff, shod in iron
and inscribed with sigils and runes of all types, this potent artifact
contains many spell powers and other functions. Some of its powers use
charges, while others don&#8217;t. The following powers do not use charges:</p>
    <ul>
      <li>Detect magic</li>
      <li>Enlarge person (Fortitude DC 15 negates)</li>
      <li>Hold portal</li>
      <li>Light</li>
      <li>Mage armor</li>
      <li>Mage hand</li>
    </ul>
    <p>The following powers drain 1 charge per usage: </p>
    <ul>
      <li>Dispel magic</li>
      <li>Fireball (10d6 damage, Reflex DC 17 half )</li>
      <li>Ice storm</li>
      <li>Invisibility</li>
      <li>Knock</li>
      <li>Lightning bolt (10d6 damage, Reflex DC 17 half )</li>
      <li>Passwall</li>
      <li>Pyrotechnics (Will or Fortitude DC 16 negates)</li>
      <li>Wall of fire</li>
      <li>Web</li>
    </ul>
    <p>These powers drain 2 charges per usage: </p>
    <ul>
      <li>Monster summoning IX</li>
      <li>Plane shift (Will DC 21 negates)</li>
      <li>Telekinesis (400 lb. maximum weight; Will DC 19 negates)</li>
    </ul>
    <p>A staff of the magi gives the wielder spell resistance 23. If
this is willingly lowered, however, the staff can also be used to
absorb arcane spell energy directed at its wielder, as a rod of
absorption does. Unlike the rod, this staff converts spell levels into
charges rather than retaining them as spell energy usable by a
spellcaster. If the staff absorbs enough spell levels to exceed its
limit of 50 charges, it explodes as if a retributive strike had been
performed (see below). The wielder has no idea how many spell levels
are cast at her, for the staff does not communicate this knowledge as a
rod of absorption does. (Thus, absorbing spells can be risky.)</p>
    <a id="staff-of-the-magi-retributive-strike"></a><p><i>Retributive Strike</i>: A staff of the magi can be broken
for a retributive strike. Such an act must be purposeful and declared
by the wielder. All charges in the staff are released in a 30-foot
spread. All within 10 feet of the broken staff take hit points of
damage equal to 8 times the number of charges in the staff, those
between 11 feet and 20 feet away take points equal to 6 times the
number of charges, and those 21 feet to 30 feet distant take 4 times
the number of charges. A DC 17 Reflex save reduces damage by half. </p>
    <p>The character breaking the staff has a 50% chance (01&#8211;50 on
d%) of traveling to another plane of existence, but if she does not
(51&#8211;100), the explosive release of spell energy destroys her. Only
specific items, including the staff of the magi and the staff of power
are capable of a retributive strike.</p>
    <p>Strong (all schools); CL 20th;Weight 5 lb.</p>
    <a id="talisman-of-pure-good"></a><p><b>Talisman of Pure Good</b>: A good (LG, NG, CG) divine
spellcaster who possesses this item can cause a flaming crack to open
at the feet of an evil (LE, NE, CE) divine spellcaster who is up to 100
feet away. The intended victim is swallowed up forever and sent
hurtling to the center of the earth. The wielder of the talisman must
be good, and if he is not exceptionally pure in thought and deed the
evil character gains a DC 19 Reflex saving throw to leap away from the
crack. Obviously, the target must be standing on solid ground for this
item to function. </p>
    <p>A talisman of pure good has 6 charges. If a neutral (LN, N,
CN) divine spellcaster touches one of these stones, he takes 6d6 points
of damage. If an evil divine spellcaster touches one, he takes 8d6
points of damage. All other characters are unaffected by the device.</p>
    <p>Strong evocation [good]; CL 18th.</p>
    <a id="talisman-of-the-sphere"></a><p><b>Talisman of the Sphere</b>: This small adamantine loop and
handle are useless to those unable to cast arcane spells. Characters
who cannot cast arcane spells take 5d6 points of damage merely from
picking up and holding a talisman of this sort. However, when held by
an arcane spellcaster who is concentrating on control of a sphere of
annihilation, a talisman of the sphere doubles the character&#8217;s modifier
on his control check (doubling both his Intelligence bonus and his
character level for this purpose).</p>
    <p>If the wielder of a talisman establishes control, he need
check for maintaining control only every other round thereafter. If
control is not established, the sphere moves toward him. Note that
while many spells and effects of cancellation have no effect upon a
sphere of annihilation, the talisman&#8217;s power of control can be
suppressed or canceled.</p>
    <p>Strong transmutation; CL 16th;Weight 1 lb.</p>
    <a id="talisman-of-reluctant-wishes"></a><p><b>Talisman of Reluctant Wishes</b>: A talisman of this sort
appears the same as a stone of controlling earth elementals. Its powers
are quite different, however, and dependent on the Charisma of the
individual holding the talisman. Whenever a character touches a
talisman of reluctant wishes, he must make a DC 15 Charisma check. </p>
    <p>If he fails, the device acts as a stone of weight. Discarding
or destroying it results in 5d6 points of damage to the character and
the disappearance of the talisman. </p>
    <p>If he succeeds, the talisman remains with the character for
5d6 hours, or until a wish is made with it, whichever comes first. It
then disappears.</p>
    <p>If he rolls a natural 20, the character finds it impossible to
be rid of the talisman for as many months as he has points of Charisma.
In addition, the artifact grants him one wish for every 6 points of the
character&#8217;s Charisma. It also grows warm and throbs whenever its
possessor comes within 20 feet of a mechanical or magic trap. (If the
talisman is not held, its warning heat and pulses are of no avail.)</p>
    <p>Regardless of which reaction results, a talisman of reluctant
wishes disappears when its time period expires, leaving behind a 10,000
gp diamond in its stead.</p>
    <p>Strong conjuration; CL 20th;Weight 1 lb.</p>
    <a id="talisman-of-ultimate-evil"></a><p><b>Talisman of Ultimate Evil</b>: An evil (LE, NE, CE) divine
spellcaster who possesses this item can cause a flaming crack to open
at the feet of a good (LG, NG, CG) divine spellcaster who is up to 100
feet away. The intended victim is swallowed up forever and sent
hurtling to the center of the earth. The wielder of the talisman must
be evil, and if she is not exceptionally foul and perverse in the
sights of her evil deity the good character gains a DC 19 Reflex save
to leap away from the crack. Obviously, the target must be standing on
solid ground for this item to function. </p>
    <p>A talisman of ultimate evil has 6 charges. If a neutral (LN,
N, CN) divine spellcaster touches one of these stones, she takes 6d6
points of damage. If a good divine spellcaster touches one, she takes
8d6 points of damage. All other characters are unaffected by the
device. </p>
    <p>Strong evocation [evil]; CL 18th.</p>
    <a id="major-artifacts"></a><h5>MAJOR ARTIFACTS</h5>
    <p className="initial">Major artifacts are unique items&#8212;only one of
each such item exists. These are the most potent of magic items,
capable of altering the balance of a campaign.</p>
    <p>Unlike all other magic items, major artifacts are not easily
destroyed. Each should have only a single, specific means of
destruction.</p>
    <a id="major-artifact-descriptions"></a><h6>Major Artifact Descriptions</h6>
    <a id="the-moaning-diamond"></a><p><b>The Moaning Diamond</b>: The Moaning Diamond appears to be
an uncut diamond the size of a human fist. At all times, it gives forth
a baleful moaning sound, as if in pain. Despite the noise, the Moaning
Diamond is not evil. The wielder of the stone can, three times per day,
call upon it to reshape earth and stone as if by the spell stone shape,
affecting 5,000 cubic feet of material. The Moaning Diamond can summon
an elder earth elemental with maximum hit points that serves the caster
until it is slain. Only one such elemental can be summoned at a time;
if it is slain, a new creature cannot be summoned for 24 hours. </p>
    <a id="the-orbs-of-dragonkind"></a><p><b>The Orbs of Dragonkind</b>: Each of these fabled orbs
contains the essence and personality of an ancient dragon of a
different variety (one for each of the major ten different chromatic
and metallic dragons). The bearer of an Orb can dominate dragons of its
particular variety within 500 feet (as dominate monster), the dragon
being forced to make a DC 25 Will save to resist. (Spell resistance is
not useful against this effect.) Each Orb of Dragonkind bestows upon
the wielder the AC and saving throw bonuses of the dragon within. These
values replace whatever values the character would otherwise have,
whether they are better or worse. These values cannot be modified by
any means short of ridding the character of the Orb. A character
possessing an Orb of Dragonkind is immune to the breath weapon&#8212;but only
the breath weapon&#8212;of the dragon variety keyed to the Orb. Finally, a
character possessing an Orb can herself use the breath weapon of the
dragon in the Orb three times per day.</p>
    <p>All Orbs of Dragonkind can be used to communicate verbally and
visually with the possessors of the other Orbs. The owner of an Orb
knows whether there are dragons within 10 miles at all times. For
dragons of the Orb&#8217;s particular variety, the range is 100 miles. If
within 1 mile of a dragon of the Orb&#8217;s variety, the wielder can
determine the exact location and age of the creature. The bearer of one
of these Orbs earns the enmity forever of all dragonkind for profiting
by the enslavement of one of their kin, even if she later loses the
item. </p>
    <p>Each Orb also has an individual power that can be invoked once
per round at 10th caster level.</p>
    <ul>
      <li>
        <p><i>Black Dragon Orb</i>: Fly (Will DC 17 negates).</p>
      </li>
      <li>
        <p><i>Blue Dragon Orb</i>: Haste (Fortitude DC 17 negates).</p>
      </li>
      <li>
        <p><i>Brass Dragon Orb</i>: Teleport (Will DC 19 negates).</p>
      </li>
      <li>
        <p><i>Bronze Dragon Orb</i>: Scrying (Will DC 18 negates).</p>
      </li>
      <li>
        <p><i>Copper Dragon Orb</i>: Suggestion (Will DC 17 negates).</p>
      </li>
      <li>
        <p><i>Gold Dragon Orb</i>: The owner of the gold Orb can call
upon any power possessed by one of the other Orbs&#8212;including the
dominate and breath weapon abilities but not AC, save bonuses, or
breath weapon immunity&#8212;but can only use an individual power once per
day. She can use dominate on any other possessor of an Orb within 1
mile (Will DC 23 negates).</p>
      </li>
      <li>
        <p><i>Green Dragon Orb</i>: Spectral hand.</p>
      </li>
      <li>
        <p><i>Red Dragon Orb</i>: Wall of fire.</p>
      </li>
      <li>
        <p><i>Silver Dragon Orb</i>: Cure critical wounds (Will DC 18
half ).</p>
      </li>
      <li>
        <p><i>White Dragon Orb</i>: Protection from energy (cold)
(Fortitude DC 17 negates).</p>
      </li>
    </ul>
    <a id="the-saints-mace"></a><p><b>The Saint&#8217;s Mace</b>: This relic appears to be a simple,
well-used cudgel, but its simple appearance hides great power. The
saint&#8217;s mace has a +5 enhancement bonus and functions as a heavy mace
with the holy, axiomatic, and disruption special abilities. The wielder
can project searing light from the mace at will, at caster level 20th.</p>
    <a id="the-shadowstaff"></a><p><b>The Shadowstaff</b>: This artifact was crafted centuries
ago, weaving together the wispy strands of shadow itself into a twisted
black staff. The Shadowstaff makes the wielder slightly shadowy and
incorporeal, granting him a +4 bonus to AC and Reflex saves (which
stacks with any other bonuses). However, in bright light (such as that
of the sun, but not a torch) or in absolute darkness, the wielder takes
a &#8211;2 penalty on all attack rolls, saves, and checks. </p>
    <p>The Shadowstaff also has these powers.</p>
    <ul>
      <li>
        <p><i>Summon Shadows</i>: Three times per day the staff may
summon 2d4 shadows. Immune to turning, they serve the wielder as if
called by a summon monster V spell cast at 20th level.</p>
      </li>
      <li>
        <p><i>Summon Nightshade</i>: Once per month, the staff can
summon a nightcrawler nightshade that serves the wielder as if called
by a summon monster IX spell cast at 20th level.</p>
      </li>
      <li>
        <p><i>Shadow Form</i>: Three times per day the wielder can
become a living shadow, with all the movement powers granted by the
gaseous form spell. </p>
      </li>
      <li>
        <p><i>Shadow Bolt</i>: Three times per day the staff can
project a ray attack that deals 10d6 points of cold damage to a single
target. The shadow bolt has a range of 100 feet.</p>
      </li>
    </ul>
    <a id="the-shield-of-the-sun"></a><p><b>The Shield of the Sun</b>: This +5 large shield, emblazoned
with the symbol of the sun, allows the wielder to cast spells as if she
were a 20th-level paladin with a Wisdom score of 20. The spells gained
are cumulative with any existing spells per day that the character
might have, even if she&#8217;s already a paladin. The Shield of the Sun also
grants spell resistance 15 to its wielder. It absorbs the first 10
points of damage from any energy attack (fire, cold, acid, electricity,
or sonic). In return for all this, once per year the shield&#8217;s owner
must undertake a quest (no saving throw to avoid) at the behest of a
lawful good deity. </p>
    <p>A character who is evil or chaotic (LE, NE, CE, CN, CG) gains
four negative levels if she attempts to use this artifact. Although
these negative levels never results in actual level loss, they remain
as long as the shield is in hand and cannot be overcome in any way
(including restoration spells). The negative levels disappear when the
shield is stowed or leaves the wearer&#8217;s possession.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      